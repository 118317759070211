<template>
    <div id="verify" style="min-height: 90vh;">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="login-content d-flex row">
                        <div class="col-md-6 pr-0 order-1 order-md-0">
                            <div v-bind:style="{ backgroundImage: 'url(' + require('@/assets/images/banner-login.png') + ')' }" class="login-banner d-flex">
                                <div class="align-self-center">
                                    Membangun kebersamaan  dalam menghasilkan Co-Creation dan Collaboration
                                </div>
                            </div>
                        </div>
                        <div class="login-form col-md-6 order-0 order-md-1">
                            <h3>Verify Your Email</h3>
                            <p>Silahkan verifikasi email terlebih dahulu untuk masuk ke aplikasi</p>
                            <div class="alert alert-warning mt-3 mb-4 d-block d-sm-flex align-items-center" role="alert">
                                <div>
                                    <h5 class="fw-600">Account Not Verified</h5>
                                    <p class="mb-0 fs-16">Check your email to verify your account</p>
                                </div>
                            </div>
                            <button class="btn btn-primary" @click="actionSend()">Resend Verification Link</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'

    export default {
        name: 'Verify',
        methods: {
            async actionSend () {
                const response = await axios.get('resend-verify')
                if (response.data.status) {
                    this.$swal({
                        icon: 'success',
                        title: 'Resend Verification Email Success',
                        text: response.data.message
                    })
                } else {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message
                    })
                }
            },
            async checkData () {
                const email = this.$route.query.email
                const token = this.$route.query.token

                if (email !== '' && email !== undefined) {
                    const response = await axios.get('verification-email?email=' + email + '&token=' + token)
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Verification Email Success',
                            text: response.data.message
                        })
                        localStorage.setItem('verify', new Date())
                        this.$router.go('/app/feed')
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: response.data.message
                        })
                    }
                }
            }
        },
        created () {
            this.checkData()
        }
    }
</script>
